// ** Icons Import
import { FaFileImport } from 'react-icons/fa'
import { Check } from 'react-feather'

export default [
  {
    id: 'Master',
    title: 'Master',
    icon: <FaFileImport size={20} />,
    children: [
      {
        id: 'categories',
        title: 'Categories',
        icon: <Check size={12} />,
        navLink: '/categories/list',
        resource: 'Categories'
      }
    ]
  }
]
