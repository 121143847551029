// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CategoriesService from '@services/categories'

export const getCategories = createAsyncThunk('categories/all/getCategories', async (params, { rejectWithValue }) => {
  try {
    const response = await CategoriesService.getCategories(params)

    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getArticle = createAsyncThunk('article/getArticle', async (params, { rejectWithValue }) => {
  try {
    const response = await ArticleService.getArticle(params)
    // console.log('data', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getCategoriesAll = createAsyncThunk('categories/getCategoriesAll', async (params, { rejectWithValue }) => {
  try {
    const response = await CategoriesService.getCategoriesAll(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getCategoriesById = createAsyncThunk('categories/getCategoriesById', async (id) => {
  const response = await CategoriesService.getCategoriesById(id)
  // console.log('data', response)
  return response
})

export const addCategories = createAsyncThunk('categories/addCategories', async (categories, { rejectWithValue }) => {
  try {
    const response = await CategoriesService.addCategories(categories)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateCategories = createAsyncThunk('categories/updateCategories', async (categories, { rejectWithValue }) => {
  try {
    const response = await CategoriesService.updateCategories(categories)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteCategories = createAsyncThunk('categories/deleteCategories', async (id) => {
  const response = await CategoriesService.deleteCategories(id)
  return response
})

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCategories: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCategoriesById.fulfilled, (state, action) => {
        state.selectedCategories = action.payload
        state.loading = false
      })
      .addCase(getCategoriesById.pending, (state, action) => {
        state.selectedCategories = action.payload
        state.loading = true
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default categoriesSlice.reducer
