import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getEditions = async (params) => {
  const response = await api.get(`${servicePrefix}/editions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getActiveEdition = async () => {
  const response = await api.get(`${servicePrefix}/editions/active-editions`)
  return response
}

const getEditionById = async (id) => {
  const response = await api.get(`${servicePrefix}/editions/${id}`)
  return response.data
}

const addEdition = async (edition) => {
  const response = await api.post(`${servicePrefix}/editions`, edition, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateEdition = async (edition) => {
  const { id } = edition

  const response = await api.patch(`${servicePrefix}/editions/${id}`, edition)
  return response.data
}

const deleteEdition = async (id) => {
  const response = await api.delete(`${servicePrefix}/editions/${id}`)
  return response.data
}

const getDailyReaders = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/daily-readers`)
  return response
}

const getAverageReaders = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/average-readers`)
  return response
}

const getAverageScreenTimes = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/average-screen-time`)
  return response
}

const EditionService = {
  getEditions,
  getActiveEdition,
  getEditionById,
  addEdition,
  updateEdition,
  deleteEdition,
  getDailyReaders,
  getAverageReaders,
  getAverageScreenTimes
}

export default EditionService
