// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const ArticleList = lazy(() => import('../../views/article/list'))
const ArticleView = lazy(() => import('../../views/article/view/index'))
const UploadFile = lazy(() => import('../../views/article/uploadArticle/index'))
const ArticleForm = lazy(() => import('../../views/article/edit/index'))

const ArticleRoutes = [
  {
    element: <ArticleList />,
    path: '/article/list',
    meta: {
      action: 'manage',
      resource: 'Article'
    }
  },
  {
    path: '/article/file-upload',
    element: <UploadFile />,
    meta: {
      action: 'create',
      resource: 'Article'
    }
  },
  {
    path: '/article/view/:id',
    element: <ArticleView />,
    meta: {
      action: 'read',
      resource: 'Article'
    }
  },
  {
    path: '/article/edit/:id',
    element: <ArticleForm />,
    meta: {
      action: 'update',
      resource: 'Article'
    }
  }
]

export default ArticleRoutes
