// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ArticleService from '@services/article'

export const getArticle = createAsyncThunk('article/getArticle', async (params, { rejectWithValue }) => {
  try {
    const response = await ArticleService.getArticle(params)
    // console.log('data', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getArticleById = createAsyncThunk('article/getArticleById', async (id) => {
  const response = await ArticleService.getArticleById(id)
  // console.log('data', response)
  return response
})

export const addArticle = createAsyncThunk('article/addArticle', async (article, { rejectWithValue }) => {
  try {
    const response = await ArticleService.addArticle(article)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateArticle = createAsyncThunk('article/updateArticle', async (article, { rejectWithValue }) => {
  try {
    const response = await ArticleService.updateArticle(article)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteArticle = createAsyncThunk('article/deleteArticle', async (id) => {
  const response = await ArticleService.deleteArticle(id)
  return response
})

export const articleSlice = createSlice({
  name: 'articles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedArticle: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticle.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getArticleById.fulfilled, (state, action) => {
        state.selectedArticle = action.payload
        state.loading = false
      })
      .addCase(getArticleById.pending, (state, action) => {
        state.selectedArticle = action.payload
        state.loading = true
      })
  }
})

export default articleSlice.reducer
