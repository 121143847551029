// ** Icons Import
import { FaFileContract } from 'react-icons/fa'
import { Check } from 'react-feather'

export default [
  {
    id: 'article',
    title: 'Article',
    icon: <FaFileContract size={20} />,
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Check size={12} />,
        navLink: '/article/list',
        resource: 'Article'
      }
      // {
      //   id: 'upload',
      //   title: 'Create Article',
      //   icon: <Check size={12} />,
      //   navLink: '/article/file-upload',
      //   resource: 'Article'
      // }
    ]
  }
]
