// ** Navigation imports
import dashboards from './dashboards'
import rolespermissions from './roles-permissions'
import users from './users'
// import forms from './forms'
// import tables from './tables'
import editions from './editions'
// import mediaHouses from './media-houses'
import subscription from './subscription'
import appUser from './app-user'
import report from './report'
import article from './article'
import masters from './masters'

// ** Merge & Export
export default [...dashboards, ...users, ...rolespermissions, ...masters, ...article, ...editions, ...subscription, ...appUser, ...report]
