import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getArticle = async (params) => {
  const response = await api.get(`${servicePrefix}/articles`, { params: stripEmptyObjects(params) })
  return response.data
}

const getActiveArticle = async () => {
  const response = await api.get(`${servicePrefix}/editions/active-editions`)
  return response
}

const getArticleById = async (id) => {
  const response = await api.get(`${servicePrefix}/articles/${id}`)
  return response.data
}

const addArticle = async (articleData) => {
  // console.log('articleData', articleData)

  const formData = new FormData()

  formData.append('title', articleData.title)
  formData.append('description', articleData.description)
  formData.append('content', articleData.content)
  formData.append('categoryId', articleData.categoryId)
  formData.append('attachment', articleData?.imageFile[0])

  const response = await api.post(`${servicePrefix}/articles`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateArticle = async (data) => {
  const formData = new FormData()
  formData.append('attachments', data.imageFile)
  formData.append('title', data.title)
  formData.append('description', data.description)
  formData.append('content', data.content)
  formData.append('categoryId', data.categoryId)

  const response = await api.patch(`${servicePrefix}/articles/${data?.id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const deleteArticle = async (id) => {
  const response = await api.delete(`${servicePrefix}/articles/${id}`)
  return response.data
}

const getDailyReaders = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/daily-readers`)
  return response
}

const getAverageReaders = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/average-readers`)
  return response
}

const getAverageScreenTimes = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/average-screen-time`)
  return response
}

const ArticleService = {
  getArticle,
  getActiveArticle,
  getArticleById,
  addArticle,
  updateArticle,
  deleteArticle,
  getDailyReaders,
  getAverageReaders,
  getAverageScreenTimes
}

export default ArticleService
