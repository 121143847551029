// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const CategoryList = lazy(() => import('../../views/categories/list'))
const ArticleView = lazy(() => import('../../views/categories/view/index'))
const UploadFile = lazy(() => import('../../views/categories/uploadCategories/index'))
const ArticleForm = lazy(() => import('../../views/categories/edit/index'))

const CategoriesRoutes = [
  {
    element: <CategoryList />,
    path: '/categories/list',
    meta: {
      action: 'manage',
      resource: 'Categories'
    }
  },
  {
    path: '/categories/file-upload',
    element: <UploadFile />,
    meta: {
      action: 'create',
      resource: 'Categories'
    }
  },
  {
    path: '/categories/view/:id',
    element: <ArticleView />,
    meta: {
      action: 'read',
      resource: 'Categories'
    }
  },
  {
    path: '/categories/edit/:id',
    element: <ArticleForm />,
    meta: {
      action: 'update',
      resource: 'Categories'
    }
  }
]

export default CategoriesRoutes
