import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getSubscribers = async (params) => {
  const response = await api.get(`${servicePrefix}/users`, { params: stripEmptyObjects(params) })
  return response.data
}

const getTotalSubscribers = async () => {
  const response = await api.get(`${servicePrefix}/subscriptions/total-subscribers`)
  return response
}

const getSubscriberById = async (id) => {
  const response = await api.get(`${servicePrefix}/users/${id}`)
  return response.data
}

const getAdminSubscribers = async (params) => {
  const response = await api.get(`${servicePrefix}/users/admin-subscribers`, { params: stripEmptyObjects(params) })
  return response.data
}

const userBulkUpload = async (data) => {
  const response = await api.post(`${servicePrefix}/users/bulk-upload-user`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const getInvoiceBySubscription = async (subscriptionId) => {
  const response = await api.get(`${servicePrefix}/invoices/get-invoice-by-subscription/${subscriptionId}`)
  return response.data
}

const mediaHouseSubscriberUpload = async (data) => {
  const response = await api.post(`${servicePrefix}/users/upload-media-house-subscribers`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const SubscriberService = {
  getSubscribers,
  getTotalSubscribers,
  getSubscriberById,
  getAdminSubscribers,
  userBulkUpload,
  getInvoiceBySubscription,
  mediaHouseSubscriberUpload
}

export default SubscriberService
