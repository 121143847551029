import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getCategories = async (params) => {
  const response = await api.get(`${servicePrefix}/categories`, { params: stripEmptyObjects(params) })
  return response.data
}

const getCategoriesAll = async () => {
  const response = await api.get(`${servicePrefix}/categories/all`)
  // console.log('sadflkasdfl', response)
  return response.data
}

const getActiveCategories = async () => {
  const response = await api.get(`${servicePrefix}/categories/active-categories`)
  return response
}

const getCategoriesById = async (id) => {
  const response = await api.get(`${servicePrefix}/categories/${id}`)
  return response.data
}

const addCategories = async (categoriesData) => {
  console.log('categoriesData', categoriesData)

  const response = await api.post(
    `${servicePrefix}/categories`,
    {
      name: categoriesData.name
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  return response.data
}

const updateCategories = async (categories) => {
  const { id } = categories

  const response = await api.patch(`${servicePrefix}/categories/${id}`, categories)
  return response.data
}

const deleteCategories = async (id) => {
  const response = await api.delete(`${servicePrefix}/categories/${id}`)
  return response.data
}

const getDailyReaders = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/daily-readers`)
  return response
}

const getAverageReaders = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/average-readers`)
  return response
}

const getAverageScreenTimes = async () => {
  const response = await api.get(`${servicePrefix}/screen-time/average-screen-time`)
  return response
}

const CategoriesService = {
  getCategories,
  getActiveCategories,
  getCategoriesById,
  addCategories,
  updateCategories,
  deleteCategories,
  getDailyReaders,
  getAverageReaders,
  getAverageScreenTimes,
  getCategoriesAll
}

export default CategoriesService
